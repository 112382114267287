import React from "react";
import Global, { connectGlobal } from "./global";
import PropTypes from "prop-types";
import { DatePicker as ADatePicker, Form } from "antd";
import { validateInput } from "@/common/FieldGenerator/validate_warpper";
import * as R from "ramda";

class DatePicker extends Global {
  constructor(props) {
    super(props);
    this.state = R.merge(this.state, {
      _model: R.clone(this.props.model)
    });

    // bind this
    this.onChange = this.onChange.bind(this);
  }

  onOk(value) {
    this.setState({
      _model: value,
      validate: validateInput(this.props.config.validate, this.state._model)
    });
    this.props.onInput(this.state._model);
  }

  validate() {
    const validateResult = validateInput(
      this.props.config.validate,
      this.state._model
    );
    this.setState({
      validate: validateResult
    });
    return validateResult;
  }

  render() {
    const { config, formId, ...otherProps } = this.props;
    const { label, fieldName, showTime, ...other } = config;
    const { _model, validate } = this.state;
    return (
      <Form.Item
        validateStatus={typeof validate === "string" ? "error" : "success"}
        help={typeof validate === "string" ? validate : null}
        style={{ width: "100%", marginBottom: 0, ...config.style }}
      >
        <ADatePicker
          showTime={showTime}
          placeholder={label || "Chọn thời gian"}
          onChange={this.onChange}
          onOk={this.onOk}
          {...other}
          value={_model}
          style={{ width: "100%" }}
          {...otherProps}
        />
      </Form.Item>
    );
  }
}

DatePicker.propsType = {
  onInput: PropTypes.func,
  config: PropTypes.object
};

DatePicker.defaultProps = {
  onInput: () => {},
  config: {
    label: "",
    fieldName: null
  }
};

export default connectGlobal(DatePicker);
