export const queryParams = function(location) {
  return new URLSearchParams(location.search);
};

export const paramsToObj = function(location) {
  return Object.fromEntries(new URLSearchParams(location.search).entries());
};

export const appendParams = (currentPath, key, value) => {
  let url = new URL(currentPath);
  let params = new URLSearchParams(url.search.slice(1));
  params.append(key, value);
  return params;
};
