import React from "react";
import moment from "moment";

export default {
  kanban: {
    groupBy: "status",
    columns: [
      {
        title: "Nháp",
        dataIndex: "draft"
      },
      {
        title: "Đang chờ",
        dataIndex: "queue"
      },
      {
        title: "Đang gửi",
        dataIndex: "sending"
      },
      {
        title: "Đã gửi",
        dataIndex: "sent"
      }
    ],
    render: data =>
      data && (
        <div style={{ maxWidth: "25vw", border: "none", padding: 0 }}>
          <p>{data.content}</p>
          <p>{moment(data.date).format("DD/MM/YYYY")}</p>
        </div>
      )
  },
  list: {
    columns: [
      {
        title: "Trạng thái",
        dataIndex: "status"
      },
      {
        title: "Tiêu đề",
        dataIndex: "title"
      },
      {
        title: "Thời gian",
        dataIndex: "time",
        render: data => moment(data).format("HH:mm:ss DD/MM/YYYY")
      }
    ]
  },
  filter: {
    conditions: {
      default: [
        {
          title: "Tất cả",
          id: "all"
        },
        {
          title: "Đã lưu",
          id: "archived"
        }
      ],
      custom: [
        {
          field: {
            id: "status",
            title: "Trạng thái"
          },
          is: [
            {
              id: "is",
              title: "là"
            },
            {
              id: "isNot",
              title: "không là"
            }
          ],
          value: {
            type: "Select",
            fieldName: "status",
            datasource: {
              options: [
                {
                  id: "draff",
                  title: "Nháp"
                },
                {
                  id: "queue",
                  title: "Đang chờ"
                },
                {
                  id: "sending",
                  title: "Đang gửi"
                },
                {
                  id: "sent",
                  title: "Đã gửi"
                }
              ]
            }
          }
        },
        {
          field: {
            id: "content",
            title: "Nội dung"
          },
          is: [
            {
              id: "contain",
              title: "Bao gồm"
            },
            {
              id: "notContain",
              title: "Không bao gồm"
            },
            {
              id: "equals",
              title: "là"
            },
            {
              id: "notEquals",
              title: "không là"
            }
          ],
          value: {
            type: "TextField",
            fieldName: "content"
          }
        },
        {
          field: {
            id: "isError",
            title: "Bị lỗi"
          },
          is: {
            id: "equals",
            title: "là"
          },
          value: {
            type: "Select",
            fieldName: "isError",
            datasource: {
              options: [
                {
                  id: true,
                  title: "Đúng"
                },
                {
                  id: false,
                  title: "Sai"
                }
              ]
            }
          }
        }
      ]
    },
    group: {
      default: [
        {
          id: "status",
          title: "Trạng thái"
        },
        {
          id: "sentBy",
          title: "Người gửi"
        }
      ],
      custom: [
        {
          field: {
            id: "createdBy",
            title: "Người tạo"
          }
        }
      ]
    },
    favorite: {
      default: [
        {
          id: "add",
          title: "Lưu tìm kiếm hiện tại"
        }
      ]
    }
  }
};
