import React, { Component } from "react";
import { Card, Row, Col, Button } from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FieldGenerator from "@/common/FieldGenerator";
import { withRouter } from "react-router";
import { expResolver } from "@/common/FieldGenerator/Utils/skeleton";

class PageDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: props.title,
      mode: props.mode,
      skeleton: props.skeleton,
      model: props.data || this.fetchData()
    };
    this.changeMode = this.changeMode.bind(this);
    this.save = this.save.bind(this);
    this.fieldGenerator = React.createRef();
  }

  static getDerivedStateFromProps(nextProps, currentState) {
    if (
      nextProps.title !== currentState.title ||
      nextProps.skeleton !== currentState.mode
    ) {
      return {
        title: nextProps.title,
        skeleton: nextProps.skeleton
      };
    }
    return null;
  }

  changeMode() {
    if (this.state.mode === "VIEW") {
      this.setState({ mode: "EDIT" });
    }
    if (this.state.mode === "EDIT") {
      this.setState({ mode: "VIEW" });
    }
    if (this.state.mode === "CREATE") {
      this.props.history.goBack();
    }
  }

  fetchData() {
    return {};
  }

  save() {
    console.log(this.fieldGenerator.current.validate());
  }

  render() {
    const { title, mode, model } = this.state;
    const skeleton = expResolver(this.state.skeleton);
    const header = (
      <>
        <Row>
          <Col span={12}>
            <h4>{title}</h4>
          </Col>
          <Col span={12}></Col>
        </Row>
        <Row>
          <Col span={12}>
            <Row gutter={[16, 16]}>
              <Col span={3}>
                {mode === "VIEW" && (
                  <Button
                    onClick={this.changeMode}
                    type="primary"
                    icon={"edit"}
                  >
                    Chỉnh sửa
                  </Button>
                )}
                {(mode === "CREATE" || mode === "EDIT") && (
                  <Button onClick={this.save} type="primary" icon={"save"}>
                    Lưu
                  </Button>
                )}
              </Col>
              <Col span={3}>
                <Button
                  style={{
                    display:
                      mode === "EDIT" || mode === "CREATE" ? "block" : "none"
                  }}
                  onClick={this.changeMode}
                  icon={"close"}
                >
                  Hủy
                </Button>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row type="flex" justify="end">
              <Col span={12}></Col>
              <Col span={12} style={{ textAlign: "right" }}></Col>
            </Row>
          </Col>
        </Row>
      </>
    );

    return (
      <>
        <div
          className="isp-border-bottom"
          style={{
            width: "100%",
            borderLeft: "none",
            backgroundColor: "#fff",
            padding: "24px 24px 10px 24px"
          }}
        >
          {header}
        </div>
        <Card
          style={{
            backgroundColor: "#fff",
            margin: "30px",
            border: "none"
          }}
        >
          <FieldGenerator
            ref={this.fieldGenerator}
            skeleton={skeleton[mode]}
            model={model}
          ></FieldGenerator>
        </Card>
      </>
    );
  }
}

PageDetail.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mode: PropTypes.string,
  config: PropTypes.object,
  skeleton: PropTypes.object,
  data: PropTypes.object
};

PageDetail.defaultProps = {
  id: null,
  mode: null,
  config: {},
  skeleton: {},
  data: {}
};

const mapStateToProps = state => {
  const { formModel } = state;
  return { formModel };
};

export default withRouter(connect(mapStateToProps)(PageDetail));
