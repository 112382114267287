/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import PropTypes from "prop-types";
import Global from "./../global";
import {
  Table as ATable,
  Input,
  Button,
  Popconfirm,
  Form,
  Row,
  Col,
  Icon,
  InputNumber
} from "antd";
// eslint-disable-next-line no-unused-vars
import FormElement from "@/common/FieldGenerator/form-elements";

const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends React.Component {
  getInput = () => {
    if (this.props.inputType === "number") {
      return <InputNumber />;
    }
    return <Input />;
  };

  state = {
    editingCell: false,
    editingProps: this.props.editing
  };

  static getDerivedStateFromProps(nextProps, currentState) {
    if (nextProps.editing !== currentState.editingProps) {
      return {
        editingProps: nextProps.editing
      };
    }
    return null;
  }

  toggleEdit = () => {
    const editingCell = !this.state.editingCell;
    this.setState({ editingCell }, () => {
      if (editingCell && this.input) {
        // this.input.focus();
      }
    });
  };

  save = e => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      this.toggleEdit();
      handleSave({ ...record, ...values });
    });
  };

  renderCell = form => {
    this.form = form;
    const {
      // children,
      dataIndex,
      record,
      title,
      type,
      config,
      format
    } = this.props;
    const { editingCell, editingProps } = this.state;
    const ComponentType = FormElement[type];
    return editingCell || editingProps ? (
      <Form.Item style={{ margin: 0 }}>
        {form.getFieldDecorator(dataIndex, {
          rules: [
            {
              required: true,
              message: `${title} is required.`
            }
          ],
          initialValue: record[dataIndex]
        })(
          <ComponentType
            ref={node => (this.input = node)}
            onPressEnter={this.save}
            onBlur={this.save}
            model={record[dataIndex]}
            config={config}
          />
        )}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={this.toggleEdit}
      >
        {format ? format(record[dataIndex]) : record[dataIndex]}
      </div>
    );
  };

  render() {
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      children,
      editing,
      type,
      config,
      format,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editable ? (
          <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
        ) : format ? (
          format(record[dataIndex])
        ) : (
          children
        )}
      </td>
    );
  }
}

class Table extends Global {
  constructor(props) {
    super(props);
    this.columns = Object.assign([], this.props.config.columns);
    if (props.config.canEdit) {
      this.columns.push({
        title: "",
        dataIndex: "operation",
        render: (text, record) => {
          const { editingKey } = this.state;
          const editable = this.isEditing(record);
          return !editable ? (
            <div>
              <Icon
                style={{ marginRight: 20 }}
                type="edit"
                disabled={editingKey !== ""}
                onClick={() => this.edit(record.key)}
              ></Icon>
              <Popconfirm
                title="Bạn có chắc muốn xóa?"
                onConfirm={() => this.handleDelete(record.key)}
              >
                <Icon type="delete"></Icon>
              </Popconfirm>
            </div>
          ) : (
            <div>
              <EditableContext.Consumer>
                {form => (
                  <Icon
                    style={{ marginRight: 20 }}
                    type="save"
                    onClick={() => this.saveRow(form, record.key)}
                  ></Icon>
                )}
              </EditableContext.Consumer>
              <Popconfirm
                title="Bạn có chắc muốn hủy?"
                onConfirm={() => this.cancel(record.key)}
              >
                <Icon type="close"></Icon>
              </Popconfirm>
            </div>
          );
        }
      });
    }

    this.state = {
      dataSource: props.model.map((item, index) => {
        item.__index = index;
        return item;
      }),
      count: props.model.length,
      editingKey: "",
      validate: true
    };

    this.handleDelete = this.handleDelete.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  handleDelete = key => {
    const dataSource = [...this.state.dataSource];
    this.setState({ dataSource: dataSource.filter(item => item.key !== key) });
    this.props.onInput(this.state.dataSource);
  };

  handleAdd = () => {
    const { count, dataSource } = this.state;
    const newData = {
      key: count,
      [this.props.config.rowKey || "__index"]: count
    };
    this.setState({
      dataSource: [...dataSource, newData],
      count: count + 1
    });
    this.props.onInput(this.state.dataSource);
  };

  handleSave = row => {
    const newData = [...this.state.dataSource];
    const index = newData.findIndex(item => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row
    });
    this.setState({ dataSource: newData });
    this.props.onInput(this.state.dataSource);
  };

  saveRow(form, key) {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }
      const newData = [...this.state.dataSource];
      const index = newData.findIndex(item => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row
        });
        this.setState({ dataSource: newData, editingKey: "" });
        this.props.onInput(this.state.dataSource);
      } else {
        newData.push(row);
        this.setState({ dataSource: newData, editingKey: "" });
        this.props.onInput(this.state.dataSource);
      }
    });
  }

  edit(key) {
    this.setState({ editingKey: key });
  }

  cancel = () => {
    this.setState({ editingKey: "" });
  };

  isEditing = record => record.key === this.state.editingKey;

  render() {
    const { dataSource } = this.state;
    const { config } = this.props;
    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell
      }
    };

    const columns = this.columns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          editable: config.canEdit && col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
          editing: this.isEditing(record),
          type: col.type,
          config: col.config,
          format: col.format
        })
      };
    });
    return (
      <div>
        <Row justify="end">
          <Col span={24} style={{ textAlign: "right" }}>
            {config.canCreate && (
              <Button
                onClick={this.handleAdd}
                type="primary"
                style={{ marginBottom: 16 }}
                icon="plus"
              ></Button>
            )}
          </Col>
        </Row>
        <ATable
          components={components}
          rowClassName={() => "editable-row"}
          bordered
          dataSource={dataSource}
          columns={columns}
          rowKey={config.rowKey || "__index"}
          pagination={config.pagination}
        />
      </div>
    );
  }
}

Table.propsType = {
  onInput: PropTypes.func,
  config: PropTypes.object
};

Table.defaultProps = {
  onInput: () => {},
  config: {
    label: "",
    fieldName: null,
    datasource: {
      options: [],
      labelField: "title",
      valueField: "id"
    },
    columns: [],
    rowKey: record => record.__index
  },
  model: []
};

export default Table;
