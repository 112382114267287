/* eslint-disable no-eval */
import React from "react";
import { clone } from "ramda";
import { ACCEPT_COMP } from "./../form-elements";
// eslint-disable-next-line no-useless-escape
const REGEX = /(\#\#\{)(.*)(\})/;

export const expResolver = object => {
  let cloneObject = clone(object);
  if (typeof cloneObject === "string") {
    if (REGEX.test(cloneObject)) {
      let fullMatch = REGEX.exec(cloneObject);
      if (fullMatch) {
        let value = fullMatch[2];
        cloneObject = cloneObject.replace(fullMatch[0], eval(value));
      }
    }
  }
  for (let key in cloneObject) {
    if (typeof cloneObject[key] === "object") {
      cloneObject[key] = expResolver(cloneObject[key]);
    } else {
      if (REGEX.test(cloneObject[key])) {
        let fullMatch = REGEX.exec(cloneObject[key]);
        if (fullMatch) {
          let value = fullMatch[2];
          cloneObject[key] = eval(value);
        }
      }
    }
  }
  return cloneObject;
};

export const getComponent = function(name, props) {
  let Comp = ACCEPT_COMP[name];
  return <Comp {...props} />;
};
