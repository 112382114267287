import React, { forwardRef } from "react";
import { Typography as ATypography } from "antd";

function Typography(props, ref) {
  const { config, model } = props;
  const { label, level, fieldName, validate, typoType, ...other } = config;
  const CompName = ATypography[typoType];
  return (
    <div ref={ref}>
      <CompName level={level} {...other} style={{ ...config.style }}>
        {config.value || model}
      </CompName>
    </div>
  );
}

export default forwardRef(Typography);
