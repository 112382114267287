import React, { Component } from "react";
import { Row, Col } from "antd";
import { Translation } from "react-i18next";
export default class componentName extends Component {
  render() {
    const { title, right, left, content } = this.props;
    return (
      <>
        <div
          className="isp-border-bottom"
          style={{
            width: "100%",
            borderLeft: "none",
            marginBottom: 10,
            padding: "24px 24px 10px 24px",
            backgroundColor: "#fff"
          }}
        >
          <Row>
            <Col span={12}>
              <Row>
                <Col span={22}>
                  <Translation>{t => <h4>{t(title)}</h4>}</Translation>
                </Col>
                <Col span={22}>
                  <h4>{left}</h4>
                </Col>
              </Row>
            </Col>
            <Col span={12}>{right}</Col>
          </Row>
        </div>
        {content}
      </>
    );
  }
}
