import React, { Component } from "react";
import { connect } from "react-redux";
import { updateMenuTitle, updateMenu } from "@/store/actions";
import { MENU } from "@/modules/email-marketing/";
import DetailPage from "@/common/pages/detail";
import Skeleton from "./skeleton";
import PropTypes from "prop-types";
// import PropTypes from "prop-types";

class EmailDetail extends Component {
  constructor(props) {
    super(props);
    this.props.updateMenuTitle("Marketing");
    this.props.updateMenu(MENU);
    this.state = {};
  }

  render() {
    return (
      <div>
        <DetailPage
          title={"Email Marketing"}
          skeleton={Skeleton}
          mode={this.props.mode}
          id={this.props.id}
          data={{
            subject: "hello world",
            sendStatus: [
              {
                received: "Test",
                time: "2020-03-03T09:29:26.211Z",
                status: "Đã gửi"
              }
            ],
            emailContent: `<strong>
Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque malesuada velit at magna efficitur fermentum. Ut et massa blandit, placerat orci vel, ultrices ligula. Curabitur in magna nulla. Donec nisi dolor, accumsan nec lectus sed, scelerisque placerat quam. Nulla laoreet turpis et erat placerat ullamcorper. Aenean venenatis sem quis rhoncus viverra. Donec ipsum leo, sagittis a interdum sit amet, ullamcorper tincidunt urna. Nam vitae elit auctor, commodo dolor ut, dapibus quam. Quisque mattis nibh vel enim rhoncus dignissim. Aliquam pellentesque magna a quam suscipit tincidunt. Duis facilisis faucibus libero, in varius mi. Aenean suscipit turpis et dolor feugiat, id tempor arcu tempus.
</strong>
<p>
Ut vel eleifend turpis. Pellentesque tincidunt odio enim, vitae sodales arcu lacinia non. Fusce neque lorem, aliquam vel ex sed, bibendum interdum mi. Pellentesque ornare tellus sed augue aliquet congue. Proin sit amet fermentum mi. Vestibulum ultrices velit non nisi venenatis, vel bibendum nisl aliquam. Duis egestas auctor rutrum. Nullam elementum ut sapien vel sodales. Suspendisse odio erat, tincidunt commodo posuere sit amet, mattis at urna. Praesent dictum urna id ante tristique ultrices. Sed mollis purus urna, sed vehicula magna facilisis non.
</p>
<p>
Aliquam enim magna, venenatis et est eu, suscipit cursus felis. Duis vestibulum non mi et lacinia. Curabitur ligula arcu, pharetra sed velit ut, venenatis laoreet lacus. Donec interdum nulla sed eros rhoncus, nec finibus nibh posuere. Proin vitae risus non mauris faucibus condimentum. Ut commodo egestas tempus. Etiam dignissim diam vitae viverra imperdiet. Vestibulum venenatis, mi sed finibus viverra, ante nunc eleifend mi, quis varius justo ante et nibh. In hac habitasse platea dictumst.
</p>
<p>
Praesent a sem mauris. Nunc orci velit, mollis et blandit nec, sollicitudin sed justo. Etiam vitae metus ipsum. Aliquam a tempor nisi. Vestibulum nunc ligula, ultricies ac varius sed, rutrum eu quam. Morbi accumsan nibh nisl. Phasellus varius lectus id tellus condimentum, non fringilla nibh ullamcorper. Maecenas tincidunt aliquet mauris, convallis fringilla lacus rhoncus nec. Pellentesque ut felis vitae orci consectetur convallis.
</p>
<p>
Fusce laoreet magna neque, egestas pharetra odio viverra eu. Praesent quis elementum massa. Duis non euismod ligula. Nunc facilisis lorem et nibh pharetra maximus. Sed ut massa augue. Nulla sed neque facilisis, rhoncus libero tempor, rutrum quam. Integer tincidunt nibh ante, accumsan tristique nisl malesuada quis. In congue hendrerit varius. Proin id turpis cursus, blandit est sit amet, finibus arcu. Nulla facilisi. Aenean at porta arcu, a vehicula odio. Pellentesque at congue augue. Integer convallis nulla sit amet orci accumsan mollis. Fusce eu dui pulvinar, volutpat erat in, tincidunt est. Quisque hendrerit velit ut nulla suscipit, nec eleifend nibh tempus.
</p>`
          }}
        ></DetailPage>
      </div>
    );
  }
}

EmailDetail.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mode: PropTypes.string
};

export default connect(null, { updateMenuTitle, updateMenu })(EmailDetail);
