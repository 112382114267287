import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Button, Radio, Icon, Table, Popover, List } from "antd";
import FilterBox from "@/common/filter";
import { withRouter } from "react-router";
import * as R from "ramda";
import Kanban from "@/common/kanban";
import ConditionBox from "@/common/filter/condition_box";
import { expResolver } from "@/common/FieldGenerator/Utils/skeleton";
import TemplatePage from "./template.js";
import { Translation } from "react-i18next";

class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modeView: "kanban",
      filter: {
        showCondition: false,
        showGroup: false,
        showFavorite: false,
        listConditions: [],
        listGroups: []
      },
      config: props.config,
      resolvedConfig: expResolver(props.config)
    };
    this.addConditions = this.addConditions.bind(this);
    this.removedConditions = this.removedConditions.bind(this);
    this.handlerDetail = this.handlerDetail.bind(this);
    this.handlerCreate = this.handlerCreate.bind(this);
  }

  onChangeModeView = e => {
    this.setState({
      modeView: e.target.value
    });
  };

  addConditions(path) {
    let value = R.path(path, this.state);
    value.push({});
    this.setState(prevState => R.assocPath(path, value, prevState));
  }
  removedConditions(path, index) {
    let value = R.path(path, this.state);
    value = R.remove(index, 1, value);
    this.setState(prevState => R.assocPath(path, value, prevState));
  }

  filterConditions(filterConfig, pathListConditions, showAction = true) {
    const defaultFilter = R.path(["default"], filterConfig) || [];
    const listConditions = R.path(R.flatten([pathListConditions]), this.state);
    return (
      <div style={{ width: 230 }}>
        <List
          itemLayout="horizontal"
          dataSource={defaultFilter}
          renderItem={item => (
            <List.Item>
              <List.Item.Meta
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                title={<a onClick={() => console.log("click")}>{item.title}</a>}
              />
            </List.Item>
          )}
        />
        {listConditions &&
          listConditions.map((item, index) => (
            <div style={{ marginTop: 20 }} key={index}>
              <Row justify="space-between" style={{ marginBottom: 10 }}>
                <Col span={2}>
                  <div style={{ display: index > 0 ? "block" : "none" }}>
                    <Translation>{t => <>{t("or")}</>}</Translation>
                  </div>
                </Col>
                <Col span={22} style={{ textAlign: "right" }}>
                  <Icon
                    onClick={() =>
                      this.removedConditions(pathListConditions, index)
                    }
                    className="trigger"
                    type="delete"
                  ></Icon>
                </Col>
              </Row>
              <ConditionBox config={filterConfig} model={item} />
            </div>
          ))}
        {showAction && (
          <Row style={{ marginTop: 20 }}>
            <Col span={9}>
              <Button size="small" type="primary">
                <Translation>{t => <>{t("Apply")}</>}</Translation>
              </Button>
            </Col>
            <Col span={15}>
              <Button
                size="small"
                icon="plus"
                onClick={() => this.addConditions(pathListConditions)}
              >
                <Translation>{t => <>{t("Add condition")}</>}</Translation>
              </Button>
            </Col>
          </Row>
        )}
      </div>
    );
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.config !== prevState.config) {
      return {
        config: nextProps.config,
        resolvedConfig: expResolver(nextProps.config)
      };
    }
    return null;
  }

  handlerDetail(item) {
    // Push to detail page
    let prepareRoute = Object.assign({}, this.props.detailRoute);
    prepareRoute = R.assocPath(
      ["state", "id"],
      R.path(this.props.idPath, item),
      prepareRoute
    );
    console.log(item);
    this.props.history &&
      this.props.history.push(
        `${prepareRoute.path}?id=${R.path(this.props.idPath, item)}`,
        prepareRoute.state
      );
  }

  handlerCreate() {
    this.props.history &&
      this.props.history.push(`${this.props.detailRoute.path}`, {
        ...this.props.detailRoute.state,
        mode: "CREATE"
      });
  }

  addIndexField(data) {
    return Array.isArray(data)
      ? data.map((item, index) => {
          item["__index"] = index;
          return item;
        })
      : [];
  }

  render() {
    const { title, canCreate, cardMode } = this.props;
    const { modeView, resolvedConfig } = this.state;
    const data = this.addIndexField(this.props.data);
    let buttonCreate = canCreate ? (
      <Button type="primary" icon="plus" onClick={this.handlerCreate}>
        <Translation>{t => <>{t("create")}</>}</Translation>
      </Button>
    ) : (
      <span></span>
    );

    const filterBox = (
      <>
        <FilterBox />
        <Row style={{ marginTop: 5 }} type="flex" justify="end">
          <Col span={12}>
            <Popover
              content={this.filterConditions(resolvedConfig.filter.conditions, [
                "filter",
                "listConditions"
              ])}
              trigger="click"
              visible={this.state.filter.showCondition}
              placement="bottom"
              onVisibleChange={visible =>
                this.setState({
                  filter: {
                    ...Object.assign({}, this.state.filter),
                    showCondition: visible
                  }
                })
              }
            >
              <Button icon="filter" style={{ marginRight: 10, width: 100 }}>
                <Translation>{t => <>{t("filter")}</>}</Translation>
              </Button>
            </Popover>
            <Popover
              content={this.filterConditions(resolvedConfig.filter.group, [
                "filter",
                "listGroups"
              ])}
              trigger="click"
              visible={this.state.filter.showGroup}
              placement="bottom"
              onVisibleChange={visible =>
                this.setState({
                  filter: {
                    ...Object.assign({}, this.state.filter),
                    showGroup: visible
                  }
                })
              }
            >
              <Button icon="menu" style={{ marginRight: 10, width: 100 }}>
                <Translation>{t => <>{t("group")}</>}</Translation>
              </Button>
            </Popover>
            <Popover
              content={this.filterConditions(
                resolvedConfig.filter.favorite,
                null,
                false
              )}
              trigger="click"
              visible={this.state.filter.showFavorite}
              placement="bottom"
              onVisibleChange={visible =>
                this.setState({
                  filter: {
                    ...Object.assign({}, this.state.filter),
                    showFavorite: visible
                  }
                })
              }
            >
              <Button icon="star" style={{ width: 100 }}>
                <Translation>{t => <>{t("favorite")}</>}</Translation>
              </Button>
            </Popover>
          </Col>
          <Col span={12} style={{ textAlign: "right" }}>
            <Radio.Group
              defaultValue="kanban"
              buttonStyle="solid"
              onChange={this.onChangeModeView}
              value={this.state.modeView}
            >
              <Radio.Button value="kanban">
                <Icon type="appstore" />
              </Radio.Button>
              <Radio.Button value="list">
                <Icon type="bars" />
              </Radio.Button>
            </Radio.Group>
          </Col>
        </Row>
      </>
    );
    return (
      <>
        <TemplatePage
          title={title}
          left={buttonCreate}
          right={filterBox}
          content={
            (modeView === "kanban" && (
              <Kanban
                data={data}
                groupBy={resolvedConfig.kanban.groupBy}
                columns={resolvedConfig.kanban.columns}
                titleRender={resolvedConfig.kanban.titleRender}
                description={resolvedConfig.kanban.render}
                onCardClick={this.handlerDetail}
                cardKey="id"
                cardMode={cardMode}
              />
            )) ||
            (modeView === "list" && (
              <Table
                columns={resolvedConfig.list.columns}
                dataSource={data}
                rowKey="__index"
                pagination={false}
              ></Table>
            ))
          }
        />
      </>
    );
  }
}

LandingPage.propTypes = {
  title: PropTypes.string,
  canCreate: PropTypes.bool,
  data: PropTypes.array,
  config: PropTypes.object
};

LandingPage.defaultProps = {
  title: "",
  canCreate: true,
  data: [],
  config: {
    kanban: {
      cardMode: false,
      columns: []
    },
    list: {
      columns: []
    },
    filter: {}
  },
  detailRoute: {
    path: null,
    state: {
      props: {
        mode: null,
        id: null
      }
    }
  },
  idPath: ["id"]
};

export default withRouter(LandingPage);
