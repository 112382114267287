import React, { Component } from "react";
import LandingPages from "@/common/pages/landing";
import { connect } from "react-redux";
import { updateMenuTitle, updateMenu } from "@/store/actions";
import { MENU } from "@/modules/email-marketing";
import config from "./config";
import mockData from "./mockData";

export const TITLE = "contactGroup";
class MailingContact extends Component {
  constructor(props) {
    super(props);
    this.props.updateMenuTitle(TITLE);
    this.props.updateMenu(MENU);
    this.state = {};
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {}

  componentWillUnmount() {}

  render() {
    return (
      <LandingPages
        title={TITLE}
        canCreate
        config={config}
        data={mockData}
        cardMode={true}
        detailRoute={{
          path: "/maillings-list/detail",
          state: {
            mode: "VIEW",
            id: null
          }
        }}
      ></LandingPages>
    );
  }
}

// MailingContact.propTypes = {

// }

export default connect(null, { updateMenuTitle, updateMenu })(MailingContact);
