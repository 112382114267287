/* eslint-disable no-template-curly-in-string */

export default {
  kanban: {
    groupBy: "status",
    columns: [],
    titleRender: "##{data => data && data.name}",
    render: "##{data => data && data.email}"
  },
  list: {
    columns: [
      {
        title: "Tên",
        dataIndex: "name"
      },
      {
        title: "Email",
        dataIndex: "email"
      }
    ]
  },
  filter: {
    conditions: {
      default: [
        {
          title: "Tất cả",
          id: "all"
        },
        {
          title: "Đã lưu",
          id: "archived"
        }
      ],
      custom: [
        {
          field: {
            id: "status",
            title: "Trạng thái"
          },
          is: [
            {
              id: "is",
              title: "là"
            },
            {
              id: "isNot",
              title: "không là"
            }
          ],
          value: {
            type: "Select",
            fieldName: "status",
            datasource: {
              options: [
                {
                  id: "draff",
                  title: "Nháp"
                },
                {
                  id: "queue",
                  title: "Đang chờ"
                },
                {
                  id: "sending",
                  title: "Đang gửi"
                },
                {
                  id: "sent",
                  title: "Đã gửi"
                }
              ]
            }
          }
        },
        {
          field: {
            id: "content",
            title: "Nội dung"
          },
          is: [
            {
              id: "contain",
              title: "Bao gồm"
            },
            {
              id: "notContain",
              title: "Không bao gồm"
            },
            {
              id: "equals",
              title: "là"
            },
            {
              id: "notEquals",
              title: "không là"
            }
          ],
          value: {
            type: "TextField",
            fieldName: "content"
          }
        },
        {
          field: {
            id: "isError",
            title: "Bị lỗi"
          },
          is: {
            id: "equals",
            title: "là"
          },
          value: {
            type: "Select",
            fieldName: "isError",
            datasource: {
              options: [
                {
                  id: true,
                  title: "Đúng"
                },
                {
                  id: false,
                  title: "Sai"
                }
              ]
            }
          }
        }
      ]
    },
    group: {
      default: [
        {
          id: "status",
          title: "Trạng thái"
        },
        {
          id: "sentBy",
          title: "Người gửi"
        }
      ],
      custom: [
        {
          field: {
            id: "createdBy",
            title: "Người tạo"
          }
        }
      ]
    },
    favorite: {
      default: [
        {
          id: "add",
          title: "Lưu tìm kiếm hiện tại"
        }
      ]
    }
  }
};
