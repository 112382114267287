import React, { Component, forwardRef } from "react";
import FormElements, { RENDER_ONLY } from "./form-elements";
import { connect } from "react-redux";
import { addForm, initModel } from "./stores/actions";
import * as R from "ramda";
import ID from "./UUID";

class FieldGenerator extends Component {
  constructor(props) {
    super(props);

    let skeletonArray = FieldGenerator.computedSkeleton(props.skeleton || {});
    const id = ID.uuid();
    props.addForm(id);
    props.initModel(id, Object.assign({}, this.props.model));
    this.state = {
      id,
      model: Object.assign({}, this.props.model),
      originModel: Object.assign({}, this.props.model),
      skeletonArray,
      refChilds: skeletonArray.reduce((acc, value, index) => {
        const key = `form_${id}_${index}`;
        acc[key] = React.createRef();
        return acc;
      }, {})
    };

    this.handlerInputChild = this.handlerInputChild.bind(this);
    this.validate = this.validate.bind(this);
  }

  static getDerivedStateFromProps(nextProps, currentState) {
    if (nextProps.skeleton !== currentState.skeletonArray) {
      let skeletonArray = FieldGenerator.computedSkeleton(
        nextProps.skeleton || {}
      );
      return {
        title: nextProps.title,
        skeletonArray,
        refChilds: skeletonArray.reduce((acc, value, index) => {
          const key = `form_${currentState.id}_${index}`;
          acc[key] = React.createRef();
          return acc;
        }, {})
      };
    }
    return null;
  }

  handlerInputChild(model, fieldName) {
    this.setState({
      model: R.assocPath([fieldName], model, this.state.model)
    });
  }

  static computedSkeleton(skeleton) {
    return R.reject(
      R.isNil,
      Array.isArray(skeleton.items) ? skeleton.items : R.values(skeleton.items)
    );
  }

  validate() {
    let valid = true;
    for (let index in this.state.skeletonArray) {
      valid =
        this.state.refChilds[
          `form_${this.state.id}_${index}`
        ].current.validate() === true && valid;
    }
    return valid;
  }

  render() {
    const { model, id } = this.state;
    const items = this.state.skeletonArray.map((item, index) => {
      const render = () => {
        let Element = FormElements[item.type] || (
          <div key={`form_${index}`}></div>
        );
        let modelComputed =
          RENDER_ONLY.indexOf(item.type) === -1 &&
          item.fieldName !== "{rootModel}"
            ? R.path([item.fieldName], model)
            : model;
        return (
          // <div style={{ minHeight: 60 }}>
          <Element
            ref={this.state.refChilds[`form_${this.state.id}_${index}`]}
            key={`form_${this.state.id}_${index}`}
            model={modelComputed}
            config={item}
            onInput={(data, path) =>
              this.handlerInputChild(data, path || item.fieldName)
            }
            parent=""
            formId={id}
          />
          // </div>
        );
      };
      let template = (
        <div style={{ marginTop: 15 }} key={`form_${this.state.id}_${index}`}>
          {render()}
        </div>
      );
      return template;
    });

    return <div ref={this.props.myForwardedRef}>{items}</div>;
  }
}

const mapStateToProps = state => {
  const { formModel } = state;
  return { formModel };
};
const FieldGeneratorComp = connect(mapStateToProps, { addForm, initModel })(
  FieldGenerator
);
export default forwardRef((props, ref) => (
  <FieldGeneratorComp {...props} myForwardedRef={ref} />
));
