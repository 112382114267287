import React from "react";
import Global from "./global";
import FormElements from "./../form-elements";
import PropTypes from "prop-types";
import { Row, Col } from "antd";
import * as R from "ramda";

export default class Column extends Global {
  constructor(props) {
    super(props);
    let skeletonArray = Column.computedSkeleton(
      R.path(["config"], props) || {}
    );
    this.state = {
      id: props.formId,
      skeletonArray,
      refChilds: skeletonArray.reduce((acc, value, index) => {
        const key = `form_${props.formId}_${index}`;
        acc[key] = React.createRef();
        return acc;
      }, {})
    };

    this.handlerInputChild = this.handlerInputChild.bind(this);
    this.validate = this.validate.bind(this);
  }

  static getDerivedStateFromProps(nextProps, currentState) {
    if (nextProps.config !== currentState.skeletonArray) {
      let skeletonArray = Column.computedSkeleton(
        R.path(["config"], nextProps) || {}
      );
      return {
        title: nextProps.title,
        skeletonArray,
        refChilds: skeletonArray.reduce((acc, value, index) => {
          const key = `form_${currentState.id}_${index}`;
          acc[key] = React.createRef();
          return acc;
        }, {})
      };
    }
    return null;
  }

  render() {
    const { config, model } = this.props;
    const { skeletonArray } = this.state;
    let cloneSkeleton = R.clone(skeletonArray).map((item, index) => {
      item["__index"] = index;
      return item;
    });
    const renderItem = (itemConfig, colConfig) => {
      const render = () => {
        let Element = FormElements[itemConfig.type] || (
          <div key={`form_${itemConfig.__index}`}></div>
        );
        return (
          // <div style={{ minHeight: 60 }}>
          <Element
            ref={
              this.state.refChilds[
                `form_${this.state.id}_${itemConfig.__index}`
              ]
            }
            key={`form_${this.state.id}_${itemConfig.__index}`}
            model={R.path([itemConfig.fieldName], model)}
            config={itemConfig}
            onInput={value => this.props.onInput(value, itemConfig.fieldName)}
          />
          // </div>
        );
      };
      let template = (
        <Col key={`form_${this.state.id}_${itemConfig.__index}`} {...colConfig}>
          {render()}
        </Col>
      );
      return template;
    };
    let rows = R.times(
      R.identity,
      Math.ceil(skeletonArray.length / config.columns.length)
    );
    let cols = config.columns;
    return (
      <>
        {rows.map((row, index) => {
          let rowItems = cloneSkeleton.splice(0, cols.length);
          return (
            <Row
              key={index}
              align={config.align}
              style={{ ...config.style, marginTop: 15 }}
              gutter={config.gutter}
            >
              {rowItems.map((col, colIndex) => renderItem(col, cols[colIndex]))}
            </Row>
          );
        })}
      </>
    );
  }
}

Column.propsType = {
  onInput: PropTypes.func,
  config: PropTypes.object,
  formId: PropTypes.string,
  model: PropTypes.any
};

Column.defaultProps = {
  onInput: () => {},
  config: {
    label: "",
    fieldName: null,
    items: {}
  },
  formId: null
};
