import React, { forwardRef } from "react";
// import PropTypes from "prop-types";
import { Card as ACard } from "antd";

function Card(props, ref) {
  const { config, model } = props;
  const { label, level, fieldName, html, ...other } = config;
  return (
    <ACard ref={ref} {...other}>
      {config.html && (
        <span
          dangerouslySetInnerHTML={{ __html: config.value || model }}
        ></span>
      )}
      {!config.html && (config.value || model)}
    </ACard>
  );
}

export default forwardRef(Card);

// Card.propsType = {
//   config: PropTypes.object
// };

// Card.defaultProps = {
//   config: {
//     label: "",
//     fieldName: null
//   }
// };
