import React from "react";
import { Descriptions as ADescriptions } from "antd";

export default React.forwardRef((props, ref) => {
  const { config, model } = props;
  const { label, items, ...other } = config;
  return (
    <ADescriptions ref={ref} title={label} {...other}>
      {items &&
        items.map((i, index) => {
          return (
            <ADescriptions.Item label={i.label} key={index}>
              {typeof i.value === "string" ? i.value : i.value(model)}
            </ADescriptions.Item>
          );
        })}
    </ADescriptions>
  );
});
