import TextField from "./inputs/TextField";
import Select from "./inputs/Select";
import WYSIWYG from "./inputs/WYSIWYG/";
import Table from "./inputs/Table";
import DatePicker from "./inputs/DatePicker";
import Column from "./containers/column";
import Typography from "./DataDisplay/Typography";
import Card from "./DataDisplay/Card";
import Descriptions from "./DataDisplay/Descriptions";

import { Icon } from "antd";

export default {
  TextField,
  Select,
  WYSIWYG,
  Table,
  DatePicker,
  Column,
  Typography,
  Card,
  Descriptions
};

export const RENDER_ONLY = ["Column"];

export const ACCEPT_COMP = {
  Icon
};
