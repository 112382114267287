import React, { useState } from "react";
// Route
import { BrowserRouter as Router, Link } from "react-router-dom";
import { connect } from "react-redux";
import ISPRoute from "@/services/routes";
import { Layout, Menu, Icon, Row, Col, Dropdown } from "antd";
import ISPTopNav from "@/common/TopNav";
import { updateMenuTitle, updateMenu } from "@/store/actions";
import { useTranslation } from "react-i18next";
import enflag from "@/translations/flags/en.svg";
import viflag from "@/translations/flags/vi.svg";

import "./App.css";

const { Header, Content, Footer, Sider } = Layout;

function App(props) {
  const { t, i18n } = useTranslation();

  const LIST_MENU = [
    {
      title: t("home"),
      route: {
        pathname: "/"
      },
      icon: "home",
      onClick: props => {
        props.updateMenuTitle(t("home"));
        props.updateMenu([]);
      }
    },
    {
      title: "Marketing",
      route: {
        pathname: "/email-marketing"
      },
      icon: "mail"
    }
  ];
  const LIST_LANG = [
    {
      title: "English",
      icon: enflag,
      onClick: props => {
        i18n.changeLanguage("en");
      }
    },
    {
      title: "Vietnamese",
      icon: viflag,
      onClick: props => {
        i18n.changeLanguage("vi");
      }
    }
  ];

  const menu_lang = (
    <Menu style={{ width: "100%", borderRight: "none" }} theme="light">
      {LIST_LANG.map((routeObj, index) => (
        <Menu.Item key={index}>
          <div
            style={{ textDecoration: "none", color: "#212121" }}
            onClick={routeObj.onClick}
          >
            <img
              alt="lang"
              style={{ width: "25px", marginRight: 2 }}
              src={routeObj.icon}
            />{" "}
            {routeObj.title}
          </div>
        </Menu.Item>
      ))}
    </Menu>
  );
  const menu = (
    <Menu
      style={{ width: "100%", borderRight: "none" }}
      mode="inline"
      theme="light"
    >
      {LIST_MENU.map((routeObj, index) => (
        <Menu.Item key={index}>
          <Link
            style={{ textDecoration: "none", color: "#212121" }}
            to={routeObj.route}
            onClick={() => {
              if (routeObj.onClick) routeObj.onClick(props);
              setCollapsed(true);
            }}
          >
            <Icon type={routeObj.icon} /> {routeObj.title}
          </Link>
        </Menu.Item>
      ))}
    </Menu>
  );
  const [collapsed, setCollapsed] = useState(false);

  React.useEffect(() => {});

  return (
    <Router basename={"#"}>
      <Layout>
        <Sider
          trigger={null}
          collapsedWidth={0}
          collapsible
          collapsed={collapsed}
          className="isp-border-right"
        >
          <div
            style={{
              width: "100%",
              textAlign: "center",
              marginTop: 20,
              marginBottom: 20
            }}
          >
            <img
              className="logo"
              alt="Logo"
              src={require("@/assets/logo.png")}
              height="50"
            />
          </div>
          {menu}
        </Sider>
        <Layout>
          <Header
            className="isp-border-bottom"
            style={{
              position: "fixed",
              zIndex: 9999,
              width: collapsed ? "100%" : "88%",
              paddingLeft: 20,
              paddingRight: 20
            }}
          >
            <Row align="middle" style={{ maxHeight: "100%" }} type="flex">
              <Col sm={1} xs={2}>
                <Icon
                  className="trigger"
                  style={{ fontSize: 28 }}
                  type={collapsed ? "menu-unfold" : "menu-fold"}
                  onClick={() => setCollapsed(!collapsed)}
                />
              </Col>
              <Col
                sm={4}
                xs={9}
                style={{
                  textOverflow: "ellipsis",
                  overflowX: "hidden",
                  whiteSpace: "nowrap"
                }}
              >
                <b style={{ fontSize: 18 }}>{t(props.generalInfo.menuTitle)}</b>
              </Col>
              <Col sm={17} xs={11}>
                <ISPTopNav listMenu={props.generalInfo.listMenu} />
              </Col>
              <Col sm={2} xs={2} style={{ textAlign: "right" }}>
                <Dropdown overlay={menu_lang}>
                  {i18n.language === "en" ? (
                    <div>
                      <img
                        alt=""
                        style={{ width: "25%", marginRight: 2 }}
                        src={enflag}
                      />
                      En
                    </div>
                  ) : (
                    <div>
                      <img
                        alt=""
                        style={{ width: "25%", marginRight: 2 }}
                        src={viflag}
                      />
                      Vi
                    </div>
                  )}
                </Dropdown>
              </Col>
            </Row>
          </Header>
          <Content style={{ marginTop: 62, backgroundColor: "transparent" }}>
            <div style={{ minHeight: "82vh" }}>
              <ISPRoute />
            </div>
          </Content>
          <Footer style={{ textAlign: "center" }}>ISP School ©2020</Footer>
        </Layout>
      </Layout>
    </Router>
  );
}

const mapStateToProps = state => {
  const { generalInfo } = state;
  return { generalInfo };
};

export default connect(mapStateToProps, { updateMenuTitle, updateMenu })(App);
