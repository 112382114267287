import React, { Component } from "react";
import { connect } from "react-redux";
import { updateMenuTitle, updateMenu } from "@/store/actions";
import { MENU } from "@/modules/email-marketing/";
import DetailPage from "@/common/pages/detail";
import Skeleton from "./skeleton";
import PropTypes from "prop-types";
// import PropTypes from "prop-types";
import { TITLE } from "./../index";

class ContactDetail extends Component {
  constructor(props) {
    super(props);
    this.props.updateMenuTitle("Marketing");
    this.props.updateMenu(MENU);
    this.state = {};
  }

  render() {
    return (
      <div>
        <DetailPage
          title={TITLE}
          skeleton={Skeleton}
          mode={this.props.mode}
          id={this.props.id}
          data={{
            name: "hello world",
            email: "email@email.com",
            company: "công ty"
          }}
        ></DetailPage>
      </div>
    );
  }
}

ContactDetail.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mode: PropTypes.string
};

export default connect(null, { updateMenuTitle, updateMenu })(ContactDetail);
