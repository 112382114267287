import moment from "moment";
export default {
  VIEW: {
    items: {
      top: {
        type: "Column",
        align: "middle",
        style: {
          display: "flex"
        },
        columns: [
          {
            span: 4
          },
          {
            span: 20,
            style: {
              alignSelf: "center"
            }
          }
        ],
        items: {
          labelSubject: {
            type: "Typography",
            value: "Tiêu đề:",
            typoType: "Text",
            style: {
              marginBottom: 0
            },
            strong: true
          },
          subject: {
            type: "Typography",
            fieldName: "subject",
            typoType: "Text"
          },
          labelRecipients: {
            type: "Typography",
            value: "Người nhận:",
            typoType: "Text",
            style: {
              marginBottom: 0
            },
            strong: true
          },
          recipients: {
            type: "Typography",
            fieldName: "subject",
            typoType: "Text"
          }
        }
      },
      emailContent: {
        type: "Card",
        fieldName: "emailContent",
        html: true
      },
      sendStatus: {
        type: "Table",
        fieldName: "sendStatus",
        canCreate: false,
        canEdit: false,
        pagination: false,
        columns: [
          {
            title: "Người nhận",
            dataIndex: "received",
            width: "30%",
            editable: true,
            type: "TextField"
          },
          {
            title: "Thời gian",
            dataIndex: "time",
            width: "30%",
            editable: true,
            type: "DatePicker",
            format: value => {
              return value
                ? moment
                    .utc(value)
                    .local()
                    .format("DD/MM/YYYY")
                : "-";
            }
          },
          {
            title: "Trạng thái",
            dataIndex: "status",
            width: "30%",
            editable: true,
            type: "TextField"
          }
        ]
      }
    }
  },
  EDIT: {
    items: {
      subject: {
        type: "TextField",
        validate: "required|max:200",
        fieldName: "subject",
        label: "Tiêu đề thư"
      },
      recipients: {
        type: "Select",
        label: "Người nhận",
        fieldName: "recipents",
        validate: "required",
        datasource: {
          options: [
            {
              label: "Test",
              value: "test"
            }
          ],
          labelField: "label",
          valueField: "value"
        }
      },
      emailContent: {
        type: "WYSIWYG",
        fieldName: "emailContent"
      }
    }
  },
  CREATE: {
    items: {
      subject: {
        type: "TextField",
        validate: "required|max:200",
        fieldName: "subject",
        label: "Tiêu đề thư"
      },
      recipients: {
        type: "Select",
        label: "Người nhận",
        fieldName: "recipents",
        validate: "required",
        datasource: {
          options: [
            {
              label: "Test",
              value: "test"
            }
          ],
          labelField: "label",
          valueField: "value"
        }
      },
      emailContent: {
        type: "WYSIWYG",
        fieldName: "emailContent"
      }
    }
  }
};
