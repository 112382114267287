import { UPDATE_MENU, UPDATE_MENU_TITLE } from "../actionTypes";

const initialState = {
  listMenu: [],
  menuTitle: "home"
};

export default function(state = initialState, action) {
  switch (action.type) {
    case UPDATE_MENU: {
      const { listMenu } = action.payload;
      return {
        ...state,
        listMenu
      };
    }
    case UPDATE_MENU_TITLE: {
      const { menuTitle } = action.payload;
      return {
        ...state,
        menuTitle
      };
    }
    default:
      return state;
  }
}
