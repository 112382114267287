import { INIT_MODEL, ADD_FORM, UPDATE } from "./actionTypes";
import * as R from "ramda";

const initialState = {};

const computeAbsolutePath = (xPath, relativeXPath) => {
  if (relativeXPath) {
    if (relativeXPath.indexOf("/") === 0) {
      return relativeXPath;
    } else if (relativeXPath.indexOf("/") === -1) {
      return xPath + "/" + relativeXPath;
    } else {
      var stack = xPath.split("/"),
        parts = relativeXPath.split("/");
      stack.pop();
      for (var i = 0; i < parts.length; i++) {
        if (parts[i] === ".") continue;
        if (parts[i] === "..") stack.pop();
        else stack.push(parts[i]);
      }
      return stack.join("/");
    }
  } else {
    return xPath ? xPath : "/";
  }
};

// const computeParentPath = xPath => {
//   let stack = xPath.split("/");
//   stack.pop();
//   return stack.join("/");
// };

const setValue = (entity, xPath, value) => {
  if (!entity) return;
  let levels = xPath.split("/");
  levels.shift();
  levels.reverse();
  let cloneRef = R.clone(entity);
  while (levels.length > 1) {
    let prop = levels[levels.length - 1];
    if (!cloneRef[prop]) {
      cloneRef[prop] = {};
    }
    cloneRef = cloneRef[prop];
    levels.pop();
  }
  cloneRef[levels[levels.length - 1]] = value;
  return cloneRef;
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ADD_FORM: {
      const { formName } = action.payload;
      return {
        [formName]: {
          entity: {},
          listeners: {}
        }
      };
    }

    case UPDATE: {
      return update(state, action.payload);
    }

    case INIT_MODEL: {
      const { formName, model } = action.payload;
      return {
        ...state,
        [formName]: {
          ...state[formName],
          entity: model
        }
      };
    }
    default:
      return state;
  }
}

function update(state, { formName, xPath, relativeXPath, value }) {
  const absolutePath = computeAbsolutePath(xPath, relativeXPath);
  return {
    [formName]: {
      ...state[formName],
      entity: setValue(R.path(["entity"], state[formName]), absolutePath, value)
    }
  };
}
