import React from "react";
import { Switch, Route } from "react-router-dom";
import Home from "@/pages/home";
import EmailRoute from "@/modules/email-marketing/route";
import { paramsToObj } from "@/utils/URLUtils";

const routes = [
  {
    path: "/",
    component: Home,
    exact: true
  },
  ...EmailRoute
];

export default function() {
  return (
    <Switch>
      {routes.map((route, i) => (
        <RouteWithSubRoutes key={i} {...route} />
      ))}
    </Switch>
  );
}

function RouteWithSubRoutes(route) {
  return (
    <Route
      path={route.path}
      exact={route.exact}
      render={props => {
        const computedProps = route.props
          ? route.props({
              params: route.location.state || {},
              query: paramsToObj(route.location) || {}
            })
          : {
              params: {},
              query: {}
            };
        return (
          <route.component
            {...props}
            {...computedProps}
            routes={route.routes}
          />
        );
      }}
    />
  );
}
