import React, { Component } from "react";
import { List, Icon } from "antd";

const data = [
  {
    title: "Ant Design Title 1"
  },
  {
    title: "Ant Design Title 2"
  },
  {
    title: "Ant Design Title 3"
  },
  {
    title: "Ant Design Title 4"
  }
];

export default class componentName extends Component {
  render() {
    return (
      <div>
        <List
          itemLayout="horizontal"
          dataSource={data}
          renderItem={item => (
            <List.Item
              extra={
                <div>
                  <Icon type="download" style={{ fontSize: 15 }} />
                </div>
              }
            >
              <List.Item.Meta
                avatar={
                  <div style={{ height: "100%", paddingTop: 6 }}>
                    <Icon type="file" style={{ fontSize: 30 }} />
                  </div>
                }
                title={<a href="https://ant.design">{item.title}</a>}
                description="Ant Design, a design language for background applications, is refined by Ant UED Team"
              />
            </List.Item>
          )}
        />
        ,
      </div>
    );
  }
}
