export default [
  {
    id: "5e5e0b313672f459be97ec83",
    name: "Imported Contact",
    listContact: [
      {
        id: "5e5e0b313672f459be97ec83",
        name: "Porter Harvey",
        email: "porterharvey@tersanki.com"
      },
      {
        id: "5e5e0b3107626fadef7f82ef",
        name: "Hazel Alston",
        email: "hazelalston@pawnagra.com"
      }
    ]
  }
];
