import React from "react";
import Global from "./../global";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "@/wysiwyg/src";
import "@/wysiwyg/css/Draft.css";
import PropTypes from "prop-types";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import * as R from "ramda";

export default class WYSIWYG extends Global {
  constructor(props) {
    super(props);
    this.state = R.merge(this.state, {
      _model: R.clone(this.props.model),
      editorState: props.model
        ? EditorState.createWithContent(
            ContentState.createFromBlockArray(
              htmlToDraft(props.model).contentBlocks
            )
          )
        : EditorState.createEmpty()
    });
    // bind this
    this.onChange = this.onChange.bind(this);
  }

  onChange(value) {
    this.setState({ editorState: value });
    this.props.onInput(draftToHtml(convertToRaw(value.getCurrentContent())));
  }

  render() {
    // const config = this.props.config || {
    //   label: ""
    // };
    const { editorState } = this.state;
    return (
      <div className="isp-border" style={styles}>
        <Editor
          editorState={editorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          onEditorStateChange={this.onChange}
        />
      </div>
    );
  }
}
const styles = {
  backgroundColor: "#fff"
};
WYSIWYG.propsType = {
  onInput: PropTypes.func,
  config: PropTypes.object
};

WYSIWYG.defaultProps = {
  onInput: () => {},
  config: {
    label: "",
    fieldName: null
  }
};
