import React from "react";
import Global, { connectGlobal } from "./global";
import PropTypes from "prop-types";
import { Input, Form } from "antd";
import * as R from "ramda";

class TextField extends Global {
  constructor(props) {
    super(props);
    this.state = R.merge(this.state, {
      _model: R.clone(this.props.model)
    });
  }

  render() {
    const { config, formId, forwardedRef, update, ...otherProps } = this.props;
    const { label, fieldName, disabled, style, ...other } = config;
    const { _model, validate } = this.state;
    return (
      <Form.Item
        ref={this.props.myForwardedRef}
        validateStatus={typeof validate === "string" ? "error" : "success"}
        help={typeof validate === "string" ? validate : null}
        style={{ marginBottom: 0, width: "100%", ...config.style }}
      >
        <Input
          addonBefore={label}
          name={fieldName}
          value={_model}
          onChange={this.onChange}
          onBlur={this.onBlur}
          disabled={disabled}
          style={{
            width: "100%",
            verticalAlign: "middle",
            ...style
          }}
          {...other}
          {...otherProps}
        />
      </Form.Item>
    );
  }
}

TextField.propsType = {
  onInput: PropTypes.func,
  config: PropTypes.object
};

TextField.defaultProps = {
  onInput: () => {},
  config: {
    label: "",
    fieldName: null
  }
};

export default connectGlobal(TextField);
