import Landing from "./index";
import Detail from "./email-detail";
import MailingContact from "./contact";
import MailingContactDetail from "./contact/detail";
import MailingGroupLanding from "./contact/group";
import MailingGroupDetail from "./contact/group/detail";
import ContentManage from "./content";

export default [
  {
    path: "/email-marketing",
    component: Landing,
    exact: true
  },
  {
    path: "/email-marketing/detail",
    component: Detail,
    exact: true,
    props: route => {
      let id = route.params.id || route.query.id;
      let mode = id
        ? route.params.mode || route.query.mode || "VIEW"
        : "CREATE";
      mode = mode === "CREATE" && id ? "VIEW" : mode;
      return {
        id,
        mode
      };
    }
  },
  {
    path: "/maillings-contact",
    component: MailingContact,
    exact: true
  },
  {
    path: "/maillings-contact/detail",
    component: MailingContactDetail,
    exact: true,
    props: route => {
      let id = route.params.id || route.query.id;
      let mode = id
        ? route.params.mode || route.query.mode || "VIEW"
        : "CREATE";
      mode = mode === "CREATE" && id ? "VIEW" : mode;
      return {
        id,
        mode
      };
    }
  },
  {
    path: "/email-marketing/content",
    component: ContentManage,
    exact: true
  },
  {
    path: "/maillings-list",
    component: MailingGroupLanding,
    exact: true
  },
  {
    path: "/maillings-list/detail",
    component: MailingGroupDetail,
    exact: true,
    props: route => {
      let id = route.params.id || route.query.id;
      let mode = id
        ? route.params.mode || route.query.mode || "VIEW"
        : "CREATE";
      mode = mode === "CREATE" && id ? "VIEW" : mode;
      return {
        id,
        mode
      };
    }
  }
];
