import React, { Component } from "react";
import TemplatePage from "@/common/pages/template";
import { connect } from "react-redux";
import { updateMenuTitle, updateMenu } from "@/store/actions";
import { MENU } from "@/modules/email-marketing/";
import FileManage from "@/common/components/FileManage";

class ManageContent extends Component {
  constructor(props) {
    super(props);
    this.props.updateMenuTitle("Marketing");
    this.props.updateMenu(MENU);
    this.state = {};
  }

  render() {
    return <TemplatePage title={"content"} content={<FileManage />} />;
  }
}

export default connect(null, { updateMenuTitle, updateMenu })(ManageContent);
