import React from "react";
import * as R from "ramda";

export default class extends React.Component {
  state = {
    validate: true
  };

  handlerInputChild(model, fieldName) {
    this.props.onInput && this.props.onInput(model, fieldName);
  }

  static computedSkeleton(skeleton) {
    return R.reject(
      R.isNil,
      Array.isArray(skeleton.items) ? skeleton.items : R.values(skeleton.items)
    );
  }

  validate() {
    let valid = true;
    for (let index in this.state.skeletonArray) {
      valid =
        this.state.refChilds[
          `form_${this.state.id}_${index}`
        ].current.validate() === true && valid;
    }
    return valid;
  }
}
