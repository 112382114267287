import React from "react";
import { Input, Icon } from "antd";
import { useTranslation } from "react-i18next";

export default function FilterBox() {
  const { t } = useTranslation();

  return (
    <div>
      <Input
        placeholder={t("search")}
        prefix={<Icon type="search" style={{ color: "rgba(0,0,0,.25)" }} />}
      />
    </div>
  );
}
