export default [
  {
    id: "1582862414373",
    title: "Our last promotions, just for you !",
    time: "2020-02-18T15:44:52.937Z",
    status: "sent",
    content:
      "By default, your site is always accessible via a Netlify subdomain based on the site name. Custom domains allow you to access your site via one or more non-Netlify domain names."
  },
  {
    id: "1582862414374",
    title: "Our last promotions, just for you 2",
    time: "2020-02-18T15:44:52.937Z",
    status: "sent",
    content: "This is newsletter"
  },
  {
    id: "1582862414375",
    title: "Đây là thư đang chờ",
    time: "2020-02-18T15:44:52.937Z",
    status: "queue",
    content: "This is newsletter"
  },
  {
    id: "1582862414376",
    title: "Đây là thư đang chờ 2",
    time: "2020-02-18T15:44:52.937Z",
    status: "queue",
    content: "This is newsletter"
  },
  {
    id: "1582862414377",
    title: "Đây là thư đang gửi",
    time: "2020-02-18T15:44:52.937Z",
    status: "sending",
    content: "This is newsletter"
  },
  {
    id: "1582862414378",
    title: "Đây là thư nháp",
    time: "2020-02-18T15:44:52.937Z",
    status: "draft",
    content: "This is newsletter"
  }
];
