import { UPDATE_MENU, UPDATE_MENU_TITLE } from "./actionTypes";

export const updateMenu = listMenu => ({
  type: UPDATE_MENU,
  payload: {
    listMenu
  }
});
export const updateMenuTitle = menuTitle => ({
  type: UPDATE_MENU_TITLE,
  payload: {
    menuTitle
  }
});
