import React, { Component } from "react";
import PropTypes from "prop-types";
import { Menu, Icon } from "antd";
import { matchPath, withRouter } from "react-router";
import { Link } from "react-router-dom";
import { path } from "ramda";
import { Translation } from "react-i18next";

const { SubMenu } = Menu;

class ISPTopNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: "0"
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.listMenu !== state.prevPropsListMenu) {
      return {
        current: ISPTopNav.checkMatchNav(props),
        prevPropsListMenu: props.listMenu
      };
    }

    return null;
  }

  static checkMatchNav(props) {
    const listMenu = props.listMenu || [];
    let indexMatch = listMenu.findIndex(item => {
      return matchPath(props.location.pathname, {
        path:
          typeof path(["route"], item) === "string"
            ? path(["route"], item)
            : path(["route", "path"], item),
        exact: true,
        strict: false
      });
    });
    return `${indexMatch}`;
  }

  handleClick = e => {
    this.setState({
      current: e.key
    });
  };

  componentDidMount() {}

  render() {
    const listMenu = this.props.listMenu || [];
    return (
      <Menu
        onClick={this.handleClick}
        selectedKeys={[this.state.current]}
        mode="horizontal"
        style={{ borderBottom: "none" }}
      >
        {listMenu.map((item, index) => {
          if (item.type === "Menu.Item") {
            return (
              <Menu.Item key={index}>
                <Link
                  style={{ textDecoration: "none", color: "#212121" }}
                  to={item.route}
                >
                  <Icon type={item.icon} />
                  <Translation>{t => <>{t(item.title)}</>}</Translation>
                </Link>
              </Menu.Item>
            );
          }
          if (item.type === "SubMenu") {
            return (
              <SubMenu
                key={index}
                title={
                  <span className="submenu-title-wrapper">
                    <Icon type={item.icon} />
                    <Translation>{t => <>{t(item.title)}</>}</Translation>
                  </span>
                }
              >
                {item.subMenus.map((subItem, subIndex) => {
                  if (subItem.type === "Menu.Item") {
                    return (
                      <Menu.Item key={`${index}:${subIndex}`}>
                        <Link
                          style={{ textDecoration: "none", color: "#212121" }}
                          to={subItem.route}
                        >
                          <Icon type={subItem.icon || "minus"} />
                          <Translation>
                            {t => <>{t(subItem.title)}</>}
                          </Translation>
                        </Link>
                      </Menu.Item>
                    );
                  }
                  return <span></span>;
                })}
              </SubMenu>
            );
          }
          return <span></span>;
        })}
      </Menu>
    );
  }
}

ISPTopNav.propTypes = {
  listMenu: PropTypes.array
};
ISPTopNav.defaultProps = {
  listMenu: []
};

export default withRouter(ISPTopNav);
