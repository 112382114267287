import React from "react";
import Global, { connectGlobal } from "./global";
import PropTypes from "prop-types";
import { Select as ASelect, Form } from "antd";
import * as R from "ramda";
const { Option } = ASelect;

const addKeyToValue = (model, keyPath) =>
  Array.isArray(model)
    ? model.map(item => R.merge({ key: R.path(keyPath, item) }, item))
    : typeof model === "object"
    ? R.merge({ key: R.path(keyPath, model) }, model)
    : model;
class Select extends Global {
  constructor(props) {
    super(props);
    this.state = R.merge(this.state, {
      _model: addKeyToValue(R.clone(props.model), [
        R.path(["datasource", "valueField"], props.config)
      ]),
      options: R.path(["config", "datasource", "options"], props) || []
    });

    // bind this
    this.onFocus = this.onFocus.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.validate = this.validate.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      !R.equals(
        R.path(["config", "datasource", "options"], nextProps),
        prevState.options
      )
    ) {
      return {
        options: R.path(["config", "datasource", "options"], nextProps) || []
      };
    }
    return null;
  }

  onChange(value) {
    console.log("on change");
    let isRawValue = R.path(["datasource", "rawValue"], this.props.config);
    let valueField =
      R.path(["datasource", "valueField"], this.props.config) || "id";
    let emitValue = Array.isArray(value)
      ? value.map(item =>
          isRawValue
            ? R.mergeAll([
                {},
                item,
                R.find(
                  R.propEq(valueField, item[valueField]),
                  this.state.options
                )
              ])
            : item
        )
      : isRawValue
      ? R.mergeAll([
          {},
          value,
          R.find(R.propEq(valueField, value[valueField]), value)
        ])
      : value;
    this.setState({ _model: emitValue });
    this.props.onInput(value);
    this.notifyChange();
  }

  onFocus() {}

  onSearch(val) {}

  render() {
    const { config, formId, ...otherProps } = this.props;
    const { _model, validate } = this.state;
    const {
      label,
      fieldName,
      showSearch,
      disabled,
      datasource,
      ...other
    } = config;
    return (
      <Form.Item
        validateStatus={typeof validate === "string" ? "error" : "success"}
        help={typeof validate === "string" ? validate : null}
      >
        <span className="ant-input-wrapper ant-input-group">
          <span
            className="ant-input-group-addon"
            style={{ display: label ? "" : "none" }}
          >
            {label}
          </span>
          <ASelect
            labelInValue={datasource.rawValue}
            showSearch={showSearch}
            onChange={this.onChange}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onSearch={this.onSearch}
            optionLabelProp="label"
            style={{
              width: "100%",
              verticalAlign: "middle"
            }}
            disabled={disabled}
            {...other}
            {...otherProps}
            value={_model}
          >
            {this.state.options &&
              this.state.options.map((item, index) => (
                <Option
                  key={
                    item[R.path(["datasource", "valueField"], config) || "id"]
                  }
                  value={
                    item[R.path(["datasource", "valueField"], config) || "id"]
                  }
                  label={
                    item[
                      R.path(["datasource", "labelField"], config) || "title"
                    ]
                  }
                >
                  {
                    item[
                      R.path(["datasource", "labelField"], config) || "title"
                    ]
                  }
                </Option>
              ))}
          </ASelect>
        </span>
      </Form.Item>
    );
  }
}

Select.propsType = {
  onInput: PropTypes.func,
  config: PropTypes.object
};

Select.defaultProps = {
  onInput: () => {},
  config: {
    label: "",
    fieldName: null,
    datasource: {
      options: [],
      labelField: "title",
      valueField: "id"
    },
    showSearch: true
  }
};

export default connectGlobal(Select);
