import React, { Component } from "react";
import PropTypes from "prop-types";
import * as R from "ramda";
import Select from "@/common/FieldGenerator/inputs/Select";
import FieldGenerator from "@/common/FieldGenerator";
class ConditionBox extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      value: null,
      is: null
    };
  }

  render() {
    const { config } = this.props;
    const custom = R.path(["custom"], config) || [];
    const listField = custom.map(item => item.field);

    return (
      <div style={this.props.style}>
        {/* Field */}
        <Select
          config={{
            label: "Điều kiện",
            datasource: {
              options: listField,
              labelField: "title",
              valueField: "id"
            },
            showSearch: true
          }}
          style={{ width: "100%" }}
          onInput={data => {
            let indexChoose = R.findIndex(R.propEq("id", data), listField);
            this.setState({
              is: indexChoose !== -1 ? custom[indexChoose].is : null,
              value: indexChoose !== -1 ? custom[indexChoose].value : null
            });
          }}
        />
        <br />
        {/* is */}
        {Array.isArray(this.state.is) ? (
          <Select
            style={{ width: "100%" }}
            config={{
              label: "Là",
              datasource: {
                options: this.state.is,
                labelField: "title",
                valueField: "id"
              },
              showSearch: true
            }}
            onInput={data => {}}
          />
        ) : (
          <span></span>
        )}
        <FieldGenerator skeleton={{ items: { main: this.state.value } }} />
      </div>
    );
  }
}

ConditionBox.propTypes = {
  config: PropTypes.object,
  style: PropTypes.object
};

export default ConditionBox;
