/* eslint-disable no-template-curly-in-string */
const SKELETON_EDIT = {
  items: {
    col: {
      type: "Column",
      align: "middle",
      // gutter: [16, 16],
      style: {
        display: "flex"
      },
      columns: [
        {
          span: 3
        },
        {
          span: 9,
          style: {
            paddingRight: 10
          }
        },
        {
          span: 12
        }
      ],
      items: {
        title: {
          type: "Select",
          fieldName: "title",
          label: "Danh xưng",
          datasource: {
            options: [
              {
                label: "Mr",
                value: "mr"
              }
            ],
            labelField: "label",
            valueField: "value"
          },
          allowClear: true
        },
        name: {
          type: "TextField",
          validate: "required|max:200",
          fieldName: "name",
          label: "##{getComponent('Icon', { type: 'user'})}"
        },
        email: {
          type: "TextField",
          validate: "required|email",
          fieldName: "email",
          label: "##{getComponent('Icon', { type: 'mail'})}"
        }
      }
    },
    company: {
      type: "TextField",
      fieldName: "company",
      label: "##{getComponent('Icon', { type: 'shop'})}"
    }
  }
};
export default {
  VIEW: {
    items: {
      info: {
        label: "Thông tin liên hệ",
        type: "Descriptions",
        fieldName: "{rootModel}",
        items: [
          {
            label: "Danh xưng",
            value: "##{value => value && value.title}"
          },
          {
            label: "Tên",
            value: "##{value => value && value.name}"
          },
          {
            label: "Email",
            value: "##{value => value && value.email}"
          },
          {
            label: "Công ty",
            value: "##{value => value && value.company}"
          }
        ]
      }
      // top: {
      //   type: "Column",
      //   align: "middle",
      //   style: {
      //     display: "flex"
      //   },
      //   columns: [
      //     {
      //       span: 4
      //     },
      //     {
      //       span: 20,
      //       style: {
      //         alignSelf: "center"
      //       }
      //     }
      //   ],
      //   items: {
      //     // labelSubject: {
      //     //   type: "Typography",
      //     //   value: "Tên:",
      //     //   typoType: "Text",
      //     //   style: {
      //     //     marginBottom: 0
      //     //   },
      //     //   strong: true
      //     // },
      //     // subject: {
      //     //   type: "Typography",
      //     //   fieldName: "name",
      //     //   typoType: "Text"
      //     // },
      //     // labelRecipients: {
      //     //   type: "Typography",
      //     //   value: "Email:",
      //     //   typoType: "Text",
      //     //   style: {
      //     //     marginBottom: 0
      //     //   },
      //     //   strong: true
      //     // },
      //     // recipients: {
      //     //   type: "Typography",
      //     //   fieldName: "email",
      //     //   typoType: "Text"
      //     // },
      //     // labelCompany: {
      //     //   type: "Typography",
      //     //   value: "Công ty:",
      //     //   typoType: "Text",
      //     //   style: {
      //     //     marginBottom: 0
      //     //   },
      //     //   strong: true
      //     // },
      //     // company: {
      //     //   type: "Typography",
      //     //   fieldName: "company",
      //     //   typoType: "Text"
      //     // }
      //   }
      // }
    }
  },
  EDIT: SKELETON_EDIT,
  CREATE: SKELETON_EDIT
};
