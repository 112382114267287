import React, { forwardRef } from "react";
import { validateInput } from "@/common/FieldGenerator/validate_warpper";
import { update } from "./../stores/actions";
import { connect } from "react-redux";

const getPath = (name, parent) => {
  if ((parent || parent === "") && name) {
    return `${parent}/${name}`;
  } else if (name) {
    return "";
  } else if (parent) {
    return parent;
  }
  return "";
};

class InputGlobal extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.notifyChange = this.notifyChange.bind(this);
    this.xPath = this.xPath.bind(this);
  }

  state = {
    validate: true,
    xPath: this.props.config.fieldName
      ? getPath(this.props.config.fieldName, this.props.parent)
      : undefined
  };

  xPath() {
    return this.props.config.fieldName
      ? getPath(this.props.config.fieldName, this.props.parent)
      : undefined;
  }

  onChange(event) {
    this.setState({
      _model: event.target.value
    });
    this.props.onInput && this.props.onInput(this.state._model);
    this.notifyChange();
  }

  onBlur(event) {
    this.props.onBlur && this.props.onBlur(event);
    this.setState({
      _model: this.state._model,
      validate: validateInput(this.props.config.validate, this.state._model)
    });
    this.props.onInput && this.props.onInput(this.state._model);
    this.notifyChange();
  }

  notifyChange() {
    this.props.update(this.props.formId, {
      xPath: this.props.parent,
      relativeXPath: this.state.xPath,
      value: this.state._model
    });
  }

  validate() {
    const validateResult = validateInput(
      this.props.config.validate,
      this.state._model
    );
    this.setState({
      validate: validateResult
    });
    return validateResult;
  }
}

export default InputGlobal;

export const connectGlobal = WrappedComp => {
  const TComp = connect(null, { update })(WrappedComp);
  const fr = forwardRef((props, ref) => (
    <TComp {...props} forwardedRef={ref} />
  ));
  fr.displayName = "Global" + WrappedComp.name;
  return fr;
};
