import {
  INIT_MODEL,
  ADD,
  ADD_XPATH_LISTENER,
  DELETE_FIELD,
  GET,
  INVOKE_CHILDREN_LISTENER,
  REMOVE_XPATH_LISTENER,
  UPDATE,
  ADD_FORM
} from "./actionTypes";

export const initModel = (formName, model) => ({
  type: INIT_MODEL,
  payload: {
    formName,
    model
  }
});

export const addXPathListener = (formName, xPath, relativePath, callback) => ({
  type: ADD_XPATH_LISTENER,
  payload: {
    formName,
    xPath,
    relativePath,
    callback
  }
});

export const removeXPathListener = (
  formName,
  xPath,
  relativePath,
  callback
) => ({
  type: REMOVE_XPATH_LISTENER,
  payload: {
    formName,
    xPath,
    relativePath,
    callback
  }
});

export const get = (formName, xPath, relativePath) => ({
  type: GET,
  payload: {
    formName,
    xPath,
    relativePath
  }
});

export const update = (formName, { xPath, relativeXPath, value }) => ({
  type: UPDATE,
  payload: {
    formName,
    xPath,
    relativeXPath,
    value
  }
});

export const deleteField = (formName, xPath, relativeXPath) => ({
  type: DELETE_FIELD,
  payload: {
    formName,
    xPath,
    relativeXPath
  }
});

export const invokeChildrenListener = (formName, xPath) => ({
  type: INVOKE_CHILDREN_LISTENER,
  payload: {
    formName,
    xPath
  }
});

export const add = (formName, xPath, relativeXPath, value) => ({
  type: ADD,
  payload: {
    formName,
    xPath,
    relativeXPath,
    value
  }
});

export const addForm = formName => ({
  type: ADD_FORM,
  payload: {
    formName
  }
});
