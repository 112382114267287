import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Board from "./board";
import * as R from "ramda";
import { Row, Col } from "antd";
import KanbanCard from "./board/components/Board/components/DefaultCard";

class Kanban extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      board: {
        columns: this.convertPropsToBoard(
          props.columns,
          this.kanbanData(props.groupBy, props.data, props.columns),
          props.cardKey,
          props.description
        )
      }
    };
  }

  kanbanData(groupBy, data, columns) {
    let mapGroup = data.map(item => {
      item[item[groupBy]] = item;
      return item;
    });
    let groupByCol = columns.map(col => {
      return R.omit(
        ["none"],
        R.groupBy(
          item => (item[col.dataIndex] ? col.dataIndex : "none"),
          mapGroup
        )
      );
    });
    let obj = R.mergeAll(groupByCol);
    obj.__index = 0;
    return [obj];
  }

  convertPropsToBoard(columns, data, cardKey, description) {
    return columns.map((item, index) => {
      let newItem = {
        id: item.dataIndex,
        title: item.title,
        cards: R.reject(
          R.isNil,
          R.flatten([R.path(["0", item.dataIndex], data)])
        ).map(subItem => ({
          ...subItem,
          title: subItem.title,
          description: description(subItem)
        }))
      };
      return newItem;
    });
    // this.setState({
    //   board: {
    //     columns:
    //   }
    // })
  }

  render() {
    const {
      onCardClick,
      cardMode,
      data,
      description,
      titleRender
    } = this.props;
    return (
      <>
        {!cardMode && (
          <Board
            initialBoard={this.state.board}
            allowAddColumn={false}
            disableColumnDrag
            allowRemoveColumn={false}
            allowRenameColumn={false}
            onCardClick={onCardClick}
          ></Board>
        )}
        {cardMode && (
          <Row align="middle" justify="center" style={{ padding: 5 }}>
            {data.map((item, index) => {
              return (
                <Col span={4} key={index}>
                  <KanbanCard onCardClick={onCardClick} allowRemoveCard={false}>
                    {{
                      ...item,
                      title: titleRender && titleRender(item),
                      description: description && description(item)
                    }}
                  </KanbanCard>
                </Col>
              );
            })}
          </Row>
        )}
      </>
    );
  }
}

Kanban.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  cardKey: PropTypes.string,
  description: PropTypes.any,
  titleRender: PropTypes.any,
  onCardClick: PropTypes.func,
  cardMode: PropTypes.bool,
  groupBy: PropTypes.string
};

Kanban.defaultProps = {
  cardMode: false,
  columns: [],
  data: [],
  cardKey: "__index",
  description: <div></div>,
  titleRender: <div></div>,
  onCardClick: () => {},
  groupBy: undefined
};

export default Kanban;
