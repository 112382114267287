import React, { Component } from "react";
import LandingPages from "@/common/pages/landing";
import { connect } from "react-redux";
import { updateMenuTitle, updateMenu } from "@/store/actions";
import config from "./config";
import mockData from "./mockData";
// import PropTypes from 'prop-types'
export const MENU = [
  {
    title: "contact",
    type: "SubMenu",
    icon: "contacts",
    subMenus: [
      {
        title: "contactList",
        route: "/maillings-contact",
        type: "Menu.Item",
        icon: "user"
      },
      {
        title: "contactGroup",
        route: "/maillings-list",
        type: "Menu.Item",
        icon: "usergroup-add"
      }
    ]
  },
  {
    title: "sendEmail",
    route: "/email-marketing",
    type: "Menu.Item",
    icon: "mail"
  },
  {
    title: "form",
    route: "/email-marketing/form",
    type: "Menu.Item",
    icon: "file-text"
  },
  {
    title: "content",
    route: "/email-marketing/content",
    type: "Menu.Item",
    icon: "hdd"
  }
];
class EmailMarketing extends Component {
  constructor(props) {
    super(props);
    this.props.updateMenuTitle("Marketing");
    this.props.updateMenu(MENU);
    this.state = {};
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {}

  componentWillUnmount() {}

  render() {
    return (
      <LandingPages
        title={"sendEmail"}
        canCreate
        config={config}
        data={mockData}
        detailRoute={{
          path: "/email-marketing/detail/",
          state: {
            mode: "VIEW",
            id: null
          }
        }}
      ></LandingPages>
    );
  }
}

// EmailMarketing.propTypes = {

// }

export default connect(null, { updateMenuTitle, updateMenu })(EmailMarketing);
