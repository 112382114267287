/* eslint-disable no-template-curly-in-string */
const SKELETON_EDIT = {
  items: {
    name: {
      type: "TextField",
      validate: "required|max:200",
      fieldName: "name",
      label: "##{getComponent('Icon', { type: 'user'})}",
      placeholder: "Tên nhóm",
      style: {
        width: "70%"
      }
    },
    listEmail: {
      type: "Select",
      fieldName: "listContact",
      label: "##{getComponent('Icon', { type: 'mail'})}",
      mode: "multiple",
      placeholder: "Danh sách liên hệ",
      datasource: {
        options: [
          {
            id: "5e5e0b313672f459be97ec83",
            name: "Porter Harvey",
            email: "porterharvey@tersanki.com"
          },
          {
            id: "5e5e0b3107626fadef7f82ef",
            name: "Hazel Alston",
            email: "hazelalston@pawnagra.com"
          },
          {
            id: "5e5e0b311bd7cf4add17d425",
            name: "Christi Prince",
            email: "christiprince@zillatide.com"
          }
        ],
        labelField: "name",
        valueField: "id",
        rawValue: true
      }
    }
  }
};
export default {
  VIEW: {
    items: {
      info: {
        label: "Thông tin nhóm liên hệ",
        type: "Descriptions",
        fieldName: "{rootModel}",
        items: [
          {
            label: "Tên nhóm",
            value: "##{value => value && value.name}"
          },
          {
            label: "Danh sách liên hệ",
            value:
              "##{value => value && value.listContact && value.listContact.map(item => item.name + ', ')}"
          }
        ]
      }
      // top: {
      //   type: "Column",
      //   align: "middle",
      //   style: {
      //     display: "flex"
      //   },
      //   columns: [
      //     {
      //       span: 4
      //     },
      //     {
      //       span: 20,
      //       style: {
      //         alignSelf: "center"
      //       }
      //     }
      //   ],
      //   items: {
      //     // labelSubject: {
      //     //   type: "Typography",
      //     //   value: "Tên:",
      //     //   typoType: "Text",
      //     //   style: {
      //     //     marginBottom: 0
      //     //   },
      //     //   strong: true
      //     // },
      //     // subject: {
      //     //   type: "Typography",
      //     //   fieldName: "name",
      //     //   typoType: "Text"
      //     // },
      //     // labelRecipients: {
      //     //   type: "Typography",
      //     //   value: "Email:",
      //     //   typoType: "Text",
      //     //   style: {
      //     //     marginBottom: 0
      //     //   },
      //     //   strong: true
      //     // },
      //     // recipients: {
      //     //   type: "Typography",
      //     //   fieldName: "email",
      //     //   typoType: "Text"
      //     // },
      //     // labelCompany: {
      //     //   type: "Typography",
      //     //   value: "Công ty:",
      //     //   typoType: "Text",
      //     //   style: {
      //     //     marginBottom: 0
      //     //   },
      //     //   strong: true
      //     // },
      //     // company: {
      //     //   type: "Typography",
      //     //   fieldName: "company",
      //     //   typoType: "Text"
      //     // }
      //   }
      // }
    }
  },
  EDIT: SKELETON_EDIT,
  CREATE: SKELETON_EDIT
};
