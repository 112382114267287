import validator from "validator";
import { isEmpty, isNil } from "ramda";

const DEFAULT_MESSAGE = {
  required: "Vui lòng nhập trường này",
  email: "Email không đúng định dạng",
  max: "Bạn đã nhập quá số lượng ký tự quy định",
  min: "Bạn đã nhập quá ít số lượng ký tự quy định"
};

const RULES = {
  required: (value, config, mess) => {
    if (isEmpty(value) || isNil(value)) {
      return mess || DEFAULT_MESSAGE.required;
    }
    return true;
  },
  email: (value, config, mess) =>
    validator.isEmail(value) || mess || DEFAULT_MESSAGE.email,
  max: (value, config, mess) =>
    (typeof value === "string" &&
      validator.isLength(value, { max: config.max || config })) ||
    mess ||
    DEFAULT_MESSAGE.max,
  min: (value, config, mess) =>
    (typeof value === "string" &&
      validator.isLength(value, { min: config.min || config })) ||
    mess ||
    DEFAULT_MESSAGE.min
};

export const validateInput = (rules, value) => {
  let splitedRules = [];
  let valid = true;
  if (typeof rules === "string") {
    splitedRules = rules.split("|");
    for (let item of splitedRules) {
      let [rule, ...config] = item.split(":");
      valid = RULES[rule] ? RULES[rule](value, config) : true;
      if (typeof valid === "string") {
        return valid;
      }
    }
  }
  if (typeof rules === "object") {
    for (let item of rules) {
      let { rule, message, ...config } = item;
      valid = RULES[rule] ? RULES[rule](value, config, message) : true;
      if (typeof valid === "string") {
        return valid;
      }
    }
  }
  return valid;
};
